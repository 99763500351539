import React, {Component} from 'react';
import {Button, Row, Col, Form, Select, message, Space, Tooltip, Radio, Input} from "antd";
import Modal from "antd/es/modal/Modal";
import {
    FormOutlined,
    QuestionCircleOutlined,
} from "@ant-design/icons";
import {getUser, Loginout} from "../../Users/AuthRouter";
import Avatar from "antd/es/avatar/avatar";
import Text from "antd/es/typography/Text";
import api from "../../../api/ApiConfig";

export class UpdateAppPConfig extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            userList: [],
            fun1: props.fun1,
            datas: props.datas,
            tp: [],
            tt: [],
        }
        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
                fun1: this.props.fun1,
            })
        }
    }

    getuserlist_atmp = async () => {
        await api.GetUserList_ATMP({}).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                let userList = []
                Object.entries(r['datas']).map(([key, value]) =>
                    userList.push({
                        value: `${value.name}(${value.user_id})`,
                        label: <Space size={5}>
                            <Avatar key={key} size={20} src={value.avatar_url}/>
                            <Text
                                style={{fontSize: 11, fontWeight: 600}}>{value.name}({value.user_id})</Text>
                        </Space>
                    })
                )
                this.setState({
                    userList: userList
                })
            }
        })
    }

    userinfo = getUser()

    testprojectsandtasks = async (params = {}) => {
        // params['UserId'] = this.userinfo.UserId
        params['ProjectTest'] = 1
        await api.TestProjectsAndTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                if (r.datas.length > 0) {
                    this.setState({
                        tp: r.datas,
                        isModalOpen: true
                    }, function () {
                        console.log("当前任务列表", this.state.tp, this.state.tt)
                    })
                }
            }
        })
    }
    post_app_project_task_config_info = async (values) => {
        values['UserId'] = this.userinfo.UserId
        values['UserName'] = this.userinfo.username
        values['configId'] = this.state.datas.key
        await api.PostAppProjectTaskConfigInfo({values}).then(r => {
            if (r.msgcode === 200) {
                message.success("更新任务配置成功")
                this.setState({
                    isModalOpen: false
                }, function () {
                    this.state.fun1()
                })
            } else if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            } else {
                message.error(r.results)
            }
        })
    }


    render() {
        const showModal = () => {
            this.getuserlist_atmp()
            this.testprojectsandtasks().then((r => {
                for (let i = 0; i < this.state.tp.length; i++) {
                    if (this.state.tp[i].ProjectName + "@" + this.state.tp[i].key === this.state.datas.AutoTestProjectInfo) {
                        this.setState({
                            tt: this.state.tp[i].TaskList.length > 0 ? this.state.tp[i].TaskList : [],
                        })
                        break
                    }
                }
            }))
        };
        const handleOk = () => {
            this.setState({
                isModalOpen: false
            }, function () {
                this.form.current.resetFields();
            })
        };
        const handleCancel = () => {
            this.setState({
                    isModalOpen: false
                }, function () {
                    this.form.current.resetFields();
                }
            )
        };
        const onFinish = (values) => {
            values['InitiateApproverList'] = JSON.stringify(values['InitiateApproverList'])
            values['ApproversList'] = JSON.stringify(values['ApproversList'])
            values['CCList'] = JSON.stringify(values['CCList'])
            values['DeveloperList'] = JSON.stringify(values['DeveloperList'])
            values['TesterList'] = JSON.stringify(values['TesterList'])
            values['OperationsList'] = JSON.stringify(values['OperationsList'])
            this.post_app_project_task_config_info(values)
        }
        return <>
            <Button size={"small"} disabled={false} style={{width: 80}}
                    type={"link"} onClick={showModal}>
                <Space size={5} style={{fontSize: 12, fontWeight: 600}}>
                    <FormOutlined/> 更新配置
                </Space>
            </Button>
            <Modal title="更新配置"
                   open={this.state.isModalOpen}
                   onOk={handleOk}
                   onCancel={handleCancel}
                   style={{minWidth: 800, marginTop: -100}}
                   footer={null}
                   maskClosable={false}
            >
                <div style={{margin: 30}}>
                    <div style={{
                        // textAlign: 'center',
                        background: "rgba(0, 0, 0, 0.02)",
                        marginTop: 10,
                        marginBottom: 20,
                        borderRadius: "8px",
                        border: "1px dashed rgb(217, 217, 217)"
                    }}>
                        <Form
                            ref={this.form}
                            name="dynamic_form_item"
                            onFinish={onFinish}
                            style={{
                                width: 470,
                                marginTop: 30,
                                marginLeft: 50,
                                fontWeight: 600
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppType"
                                        label="App类型"
                                        initialValue={this.state.datas.AppType}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择App类型",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择项目类型"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: 1,
                                                    label: 'NeoHome',
                                                },
                                                {
                                                    value: 2,
                                                    label: '公牛E家',
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppEnv"
                                        label="提测环境"
                                        initialValue={this.state.datas.AppEnv}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择提测环境",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            showSearch
                                            placeholder="请选择提测环境"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={[
                                                {
                                                    value: "TEST",
                                                    label: '测试环境',
                                                },
                                                {
                                                    value: "RELEASE",
                                                    label: '生产环境',
                                                },
                                                {
                                                    value: "PRE",
                                                    label: '预发布环境',
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="AppPlatformType"
                                        label="平台类型"
                                        initialValue={this.state.datas.AppPlatformType}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择App平台",
                                            }
                                        ]}
                                    >
                                        <Radio.Group
                                            options={[
                                                {label: 'IOS', value: 1, disabled: false},
                                                {label: 'Android', value: 2, disabled: false},
                                                {label: 'HarmonyOS', value: 3, disabled: false},
                                            ]}/>
                                    </Form.Item>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="测试项目"
                                        name="AutoTestProjectInfo"
                                        initialValue={this.state.datas.AutoTestProjectInfo}
                                        rules={[
                                            {
                                                required: true,
                                                message: "请选择项目",
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            placeholder="请选择项目"
                                            optionFilterProp="children"
                                            showSearch={true}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            onChange={(value) => {
                                                for (let i = 0; i < this.state.tp.length; i++) {
                                                    if (this.state.tp[i].ProjectName + "@" + this.state.tp[i].key === value) {
                                                        this.form.current.setFieldsValue({AutoTestTaskInfo: null})
                                                        this.setState({
                                                            tt: this.state.tp[i].TaskList.length > 0 ? this.state.tp[i].TaskList : [],
                                                        }, function () {
                                                            if (this.state.tt.length === 0) {
                                                                message.warning("当前项目没有测试任务,请先创建测试任务～")
                                                            }
                                                        })
                                                        break
                                                    }
                                                }
                                            }}
                                            options={this.state.tp.map((value, index, array) => (
                                                {
                                                    value: value.ProjectName + "@" + value.key,
                                                    label: value.ProjectName,
                                                }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="当前测试任务所属的测试项目">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label={"测试任务"}
                                        name="AutoTestTaskInfo"
                                        initialValue={this.state.datas.AutoTestTaskInfo}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择一个自动化任务',
                                            }
                                        ]}
                                    >
                                        <Select
                                            style={{textAlign: "left"}}
                                            placeholder="请选择任务"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={this.state.tt.map((value, index, array) => ({
                                                value: value.TaskName + "@" + value.key,
                                                label: value.TaskName,
                                            }))}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="当前服务可执行的自动化测试任务">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="可发起人"
                                        name="InitiateApproverList"
                                        initialValue={JSON.parse(this.state.datas.InitiateApproverList)}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择可发起人',
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            maxCount={0}
                                            style={{width: '100%'}}
                                            placeholder="请选择人员"
                                            options={this.state.userList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="飞书审批流程-该服务有权限发起部署申请的人员列表">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="审批人员"
                                        name="ApproversList"
                                        initialValue={JSON.parse(this.state.datas.ApproversList)}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择审批人',
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择人员"
                                            options={this.state.userList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="飞书审批流程-审批人员列表">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="抄送人员"
                                        name="CCList"
                                        initialValue={JSON.parse(this.state.datas.CCList)}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择抄送人',
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择人员"
                                            options={this.state.userList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="飞书审批流程-抄送人员列表">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="运维人员"
                                        name="OperationsList"
                                        initialValue={JSON.parse(this.state.datas.OperationsList)}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择运维人员',
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择人员"
                                            options={this.state.userList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="部署任务异常时会通知到运维人员">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="开发人员"
                                        name="DeveloperList"
                                        initialValue={JSON.parse(this.state.datas.DeveloperList)}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择开发人员',
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择人员"
                                            options={this.state.userList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="jenkins服务部署异常、自动化测试任务Failed时会通知到开发人员">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                                <Col span={22}>
                                    <Form.Item
                                        label="测试人员"
                                        name="TesterList"
                                        initialValue={JSON.parse(this.state.datas.TesterList)}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请选择测试人员',
                                            }
                                        ]}
                                    >
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder="请选择人员"
                                            options={this.state.userList}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Tooltip title="自动化测试任务完成时会通知到测试人员">
                                        <Button type={"link"} style={{marginLeft: 0}}><QuestionCircleOutlined/></Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 30, marginLeft: "42%"}}>
                                <Button type="primary" htmlType="submit" style={{fontSize: 13, fontWeight: 800}}>
                                    确认更新
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    }
}