import axios from 'axios';
import {base, Mfs_Host} from './UrlConfig'
import {message} from "antd";
import {getheaders} from './apiHeaders'
import {getUser, Loginout} from "../components/Users/AuthRouter";


export default {
    async GetUserInfo(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/GetUserInfo`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error(error)
                return []
            });
    },
    async GetUserList_ATMP(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetUserList_ATMP`, {params, headers: header})
            .then(response => {
                if (response.data.msgcode === 200) {
                    // eslint-disable-next-line no-sequences
                    return response.data
                } else {
                    message.error(response.data.results)
                    return response.data.msgcode
                }
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostUserList_ATMP(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/GetUserList_ATMP`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error(error)
                return []
            });
    }, async GetProducts_YPT(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProducts_YPT`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async CreateTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/CreateTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async UploadFiveTuple(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/UploadFiveTuple`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTupleApply(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetTupleApply`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelTupleApply(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelTupleApply`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetFiveTuple(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetFiveTuple`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDeviceList(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceList`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTasks(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetTasks`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetVirtualDeviceBindStatus(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetVirtualDeviceBindStatus`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async DelTasks(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelTasks`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async StartVirtualDevices(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/StartVirtualDevices`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async StopVirtualDevices(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/StopVirtualDevices`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async UnBindVirtualDevices(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/UnBindVirtualDevices`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async CreatePerformanceTestTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/CreatePerformanceTestTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetPerformanceTestTask(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetPerformanceTestTask`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelPerformanceTestTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelPerformanceTestTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetPerformanceTestItems(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetPerformanceTestItems`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetSoloPiUserDatas(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetSoloPiUserDatas`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetProject(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProject`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async ToBeDetermined(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/ToBeDetermined`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async InterfaceDatas(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/InterfaceDatas`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DeviceInterfaceDatas(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/DeviceInterfaceDatas`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDeviceInfoList(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceInfoList`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDeviceInfoListMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceInfoListMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetDeviceCategoriesListMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceCategoriesListMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetInterfaceDatasMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetInterfaceDatasMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDevicesDatasMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDevicesDatasMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetServiceAvailability(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetServiceAvailability`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDeviceAvailability(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceAvailability`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async delInterfaceDatas(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/delInterfaceDatas`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async delDeviceInterface(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/delDeviceInterface`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetInterfaceStatistic(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetInterfaceStatistic`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetDeviceStatistic(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceStatistic`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetInterfaceStatisticMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetInterfaceStatisticMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetDeviceStatisticMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDeviceStatisticMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetPatrolConfig(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetPatrolConfig`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async UpdatePatrolConfig(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/UpdatePatrolConfig`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async StartTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/StartTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async StopTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/StopTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetPatrolTaskStatus(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetPatrolTaskStatus`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetCoveredInterfaces(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetCoveredInterfaces`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDatePickerConfig(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDatePickerConfig`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async SystemAvailabilityMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/SystemAvailabilityMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async SystemAvailabilityAppMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/SystemAvailabilityAppMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async RequestCrashTrendMonth(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/RequestCrashTrendMonth`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async AppAvailabilityCrashTrendMonth(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/AppAvailabilityCrashTrendMonth`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async uploadImage(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/uploadImage`, {datas}, {
            headers: header
        }).then(response => {
            return response.data
        })
            .catch(error => {
                message.error("后端服务异常")
                return []
            });
    }, async GetAvailabilityAlert(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetAvailabilityAlert`, {params, headers: header})
            .then(response => {
                if (response.data.msgcode === 200) {
                    // eslint-disable-next-line no-sequences
                    return response.data
                } else {
                    message.error(response.data.results)
                    return response.data
                }
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });

    }, async PostAvailabilityAlert(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/PostAvailabilityAlert`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async TestCaseSync(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/TestCaseSync`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async PostTestCaseBaseConfig(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/TestCaseBaseConfig`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTestCaseBaseConfig(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/TestCaseBaseConfig`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetCaseStatisticsDatas(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetCaseStatisticsDatas`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetCaseCoverageRate(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetCaseCoverageRate`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetProjectList(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProjectList`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetTestCases(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetTestCases`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostTestProject(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/TestProject`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetTestProject(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/TestProject`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelTestProject(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelTestProject`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostTestTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/TestTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTestTask(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/TestTask`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelTestTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelTestTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async DelTestTaskBuilds(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelTestTaskBuilds`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async TestProjectsAndTasks(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/TestProjectsAndTasks`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostTestTaskCases(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/TestTaskCases`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTestTaskCases(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/TestTaskCases`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async ExecutingTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/ExecutingTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async StopTestTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/StopTestTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTestTaskBuilds(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetTestTaskBuilds`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTestTaskConfig(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetTestTaskConfig`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostTestTaskConfig(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/PostTestTaskConfig`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async SyncTaskBuildsStatus(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/SyncTaskBuildsStatus`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetBuildsStatus(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetBuildsStatus`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostDocumentManagement(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DocumentManagement`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDocumentManagement(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/DocumentManagement`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async TestDatasStatistics(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/TestDatasStatistics`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async AutoTestGitWebHook(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/AutoTestGitWebHook`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async GetProjectTasks(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProjectTasks`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostProjectTaskConfigInfo(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/ProjectTaskConfigInfo`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetProjectTaskConfigInfo(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/ProjectTaskConfigInfo`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetApprovalDatas(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetApprovalDatas`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async CommitApproval(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/CommitApproval`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetProjectTasksStatus(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProjectTasksStatus`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetApprovalResult(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetApprovalResult`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelProjectTasks(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelProjectTasks`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelProjectTaskConfigInfo(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelProjectTaskConfigInfo`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async AutoTestAgain(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/AutoTestAgain`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async SetAutoTestResult(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/SetAutoTestResult`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostSpecialConfiguration(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/SpecialConfiguration`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetSpecialConfiguration(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/SpecialConfiguration`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async ServiceDeploymentResult(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/ServiceDeploymentResult`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetYearsBugCounts(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetYearsBugCounts`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetProjectBugs(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProjectBugs`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetPersonBugs(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetPersonBugs`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetBugCounts(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetBugCounts`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetUnresolvedBugsDistribution(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetUnresolvedBugsDistribution`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDurationStay(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDurationStay`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetDurationStay_Z(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetDurationStay_Z`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async getProjectList(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/getProjectList`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetBugsDistribution(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetBugsDistribution`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetTimeInterval(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetTimeInterval`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetReopenList(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetReopenList`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostAppProjectTasks(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/AppProjectTasks`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetAppProjectTasks(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/AppProjectTasks`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelAppProjectTask(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelAppProjectTask`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async PostAppProjectTaskConfigInfo(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/AppProjectTaskConfigInfo`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetAppProjectTaskConfigInfo(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/AppProjectTaskConfigInfo`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelAppProjectTaskConfigInfo(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelAppProjectTaskConfigInfo`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async ProjectTasksAppStatus(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/ProjectTasksAppStatus`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async Get_App_Approval_Info(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/Get_App_Approval_Info`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetAppAutoTestBuildInfo(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetAppAutoTestBuildInfo`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async ExecuteAgain(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/ExecuteAgain`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async SetAppAutoTestResult(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/SetAppAutoTestResult`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetOssUpLoadUrl(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetOssUpLoadUrl`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
    async FirmwareProjectTasks(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/FirmwareProjectTasks`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelFirmwareProjectTasks(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelFirmwareProjectTasks`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetFirmwareProjectTasks(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetFirmwareProjectTasks`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async FirmwareProjectTaskConfig(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/FirmwareProjectTaskConfig`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetFirmwareProjectTaskConfig(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetFirmwareProjectTaskConfig`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async DelFirmwareProjectTaskConfig(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/DelFirmwareProjectTaskConfig`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetProjectTasksFirmwareStatus(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetProjectTasksFirmwareStatus`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async GetFirmwareApprovalInfo(params) {
        const header = getheaders(params)
        return await axios.get(`${base}/GetFirmwareApprovalInfo`, {params, headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async FirmwareExecuteAgain(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/FirmwareExecuteAgain`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    }, async FirmwareSetAutoTestResult(datas) {
        const header = getheaders(datas['values'])
        return await axios.post(`${base}/FirmwareSetAutoTestResult`, {datas}, {headers: header})
            .then(response => {
                return response.data
            })
            .catch((error) => {
                message.error("后端服务异常")
                return []
            });
    },
}
