import {
    BarChartOutlined, BugOutlined,
    CalendarOutlined,
    DashboardOutlined,
    FundViewOutlined,
    LoadingOutlined,
    ProjectOutlined,
    RedditOutlined, SettingOutlined, UserOutlined
} from '@ant-design/icons';
import {BrowserRouter, NavLink, Switch, withRouter} from 'react-router-dom';
import React from 'react';
import './MenuPage.css';
import {AuthRouter, getUser, Login, islogin} from "../Users/AuthRouter";
import UserInfo from "../Users/UserInfo"
import api from "../../api/ApiConfig";
import {Affix, Anchor, FloatButton, Layout, Menu, message, Spin} from 'antd';
import {UserCenter} from "../UserCenter/UserCenter";
import Tester from "../Tester/TestHome";
import qs from "querystring"
import {DeviceTask} from "../VdeiceTask/DeviceTask";
import {FiveTuplesHome} from "../FiveTuplesManagement/FiveTuplesHome";
import {DeviceDetails} from "../VdeiceTask/DeviceDetails";
import {PerformanceTestHome} from "../PerformanceTest/PerformanceTestHome";
import {PerformanceTestDetails} from "../PerformanceTest/PerformanceTestDetails";
import {ServiceAvailability} from "../ServiceAvailability/Home";
import {ServiceDetail} from "../ServiceAvailability/ServiceDetail";
import {DevicesDetail} from "../DeviceAvailability/DevicesDetail";
import {Repository} from "../AutoTest/CaseRepository/Repository";
import {TestProject} from "../AutoTest/TestProjectManagement/Home";
import {TaskList} from "../AutoTest/TestProjectManagement/TaskList";
import {ProjectTestingHome} from "../ProjectTesting/Home";
import {ProjectTaskDetails} from "../ProjectTesting/ProjectTaskDetails";
import {ProjectConfig} from "../ProjectTesting/ProjectConfig";
import {ProjectTestDatas} from "../ProjectTesting/ProjectTestDatas";
import {BugDashboardHome} from "../BugDashboard/BugDashboardHome";
import {UserConfig} from "../SystemConfig/UserConfig";
import {AppProjectDetails} from "../ProjectTesting/AppSubmitTest/AppProjectDetails";
import {FirmwareTaskDetails} from "../ProjectTesting/FirmwareSubmitTest/FirmwareTaskDetails";


const {
    Sider,
    Content,
    Footer,
    Header,
} = Layout;


class MenuPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedKeys: window.location.pathname,
            looading_table: false,
            items: [
                {
                    label: 'AutoTest',
                    key: 'sub1',
                    icon: <RedditOutlined/>,
                    disabled: false,
                    children: [
                        {
                            key: '/Repository',
                            label: (<NavLink to={'/Repository'}>用例仓库</NavLink>)
                        },
                        {
                            key: '/TestProject',
                            label: (<NavLink to={'/TestProject'}>测试项目</NavLink>)
                        },
                    ],
                },
                {
                    label: '性能测试',
                    key: 'sub2',
                    icon: <DashboardOutlined/>,
                    children: [
                        {
                            key: '/PerformanceTest',
                            label: (<NavLink to={'/PerformanceTest'}>App性能采集</NavLink>)
                        },
                    ],
                },
                {
                    label: '日常巡检',
                    key: 'sub3',
                    icon: <FundViewOutlined/>,
                    children: [
                        {
                            key: '/ServiceAvailability',
                            label: (<NavLink to={'/ServiceAvailability'}>系统巡检</NavLink>)
                        },
                    ],
                },
                {
                    label: '版本提测',
                    key: 'sub4',
                    icon: <ProjectOutlined/>,
                    disabled: false,
                    children: [
                        {
                            key: '/ProjectTesting',
                            label: (<NavLink to={'/ProjectTesting'}>提测任务</NavLink>)
                        }, {
                            key: '/ProjectTestConfig',
                            label: (<NavLink to={'/ProjectTestConfig'}>任务配置</NavLink>)
                        },
                        // {
                        //     key: '/ProjectTestDatas',
                        //     label: (<NavLink to={'/ProjectTestDatas'}>数据统计</NavLink>)
                        // }
                    ],
                },
                // {
                //     label: '虚拟设备',
                //     key: 'sub5',
                //     icon: <RedditOutlined/>,
                //     disabled: true,
                //     children: [
                //         {
                //             key: '/DeviceManagement',
                //             label: (<NavLink to={'/DeviceManagement'}>虚拟任务</NavLink>)
                //         },
                //         {
                //             key: '/TupleManagement',
                //             label: (<NavLink to={'/TupleManagement'}>设备管理</NavLink>)
                //         },
                //     ],
                // },
                {
                    label: '数据看板',
                    disabled: true,
                    key: 'sub6',
                    icon: <CalendarOutlined/>,
                    children: [
                        {
                            key: '/BugDashboard',
                            label: (<NavLink to={'/BugDashboard'}>
                                {/*<BugOutlined/> */}
                                Jira看板</NavLink>)
                        },
                    ],
                },
                {
                    label: '系统配置',
                    disabled: false,
                    key: 'sub7',
                    icon: <SettingOutlined/>,
                    children: [
                        {
                            key: '/UserConfig',
                            label: (<NavLink to={'/UserConfig'}>
                                {/*<UserOutlined/> */}
                                用户管理</NavLink>)
                        },
                    ],
                },
            ]
        };
        console.log("this.props.match.params.id", this.props.match.params.ly)
    }


    onselectKey = () => {
        this.setState({selectedKeys: window.location.pathname});
        // window.location.reload()
    }

    GetUserInfo = async (code) => {
        this.setState({
            looading_table: true
        })
        const response = await api.GetUserInfo({"values": {"code": code}})
        console.log("获取到的用户信息", response)
        if (response.msgcode === 20008) {
            this.props.history.push('/login')
        }
        if (response.msgcode === 200010) {
            if (response.results.user_id !== undefined
                && response.results.user_token !== undefined
                && response.results.name !== undefined
                && response.results.avatar_url !== undefined)
                Login(
                    response.results.user_id,
                    response.results.user_token,
                    response.results.name,
                    response.results.avatar_url
                )
            this.props.history.push('/')
        }
        window.location.reload()
    }

    componentDidMount() {
        if (islogin() === true) {
            this.setState({looading_table: false})
        } else {
            let {code} = qs.parse(this.props.location.search.slice(1))
            if (typeof (code) === "undefined") {
                this.props.history.push('/login')
                message.warning("用户未登录、登陆失败!")
            } else {
                this.GetUserInfo(code).then(r => {
                    this.setState({
                        looading_table: false
                    })
                })
            }
        }
    }

    render() {
        const ua = navigator.userAgent.toLowerCase();
        const agents = ['iphone', 'ipad', 'ipod', 'android', 'linux', 'windows phone'];

        for (let i = 0; i < agents.length; i++) {
            console.log("访问的设备信息", ua)
            if (ua.indexOf(agents[i]) !== -1) {
                console.log("非PC端访问", ua)
            }
        }
        return (
            <Spin tip="登陆中...." indicator={<LoadingOutlined style={{fontSize: 24, overflow: "hidden"}} spin/>}
                  spinning={this.state.looading_table}>
                <BrowserRouter>
                    <Anchor offsetTop={0} style={{marginLeft: -2, padding: 0, height: 60}}>
                        <Header style={{padding: 0, marginTop: 0, backgroundColor: '#042e79'}}>
                            <UserInfo/>
                        </Header>
                    </Anchor>
                    <Layout style={{overflow: "hidden"}}>
                        <Sider collapsible theme="light"
                               onCollapse={(collapsible) => {
                               }}
                               style={{
                                   minHeight: '93vh',
                                   backgroundColor: '#ffffff',
                               }}>
                            <Anchor offsetTop={65}>
                                <Menu theme="light"
                                      defaultOpenKeys={[]}
                                      defaultSelectedKeys={[this.state.selectedKeys]}
                                      selectedKeys={[this.state.selectedKeys]}
                                      onSelect={this.onselectKey}
                                      mode="inline"
                                    // style={{backgroundColor: '#ffffff'}}
                                      items={this.state.items}
                                />
                            </Anchor>
                        </Sider>
                        <Layout>
                            <Content>
                                <Switch>
                                    <AuthRouter exact path='/' component={Tester}/>
                                    {/*<div style={{marginTop: -30, background: '#ffffff', minHeight: 800}}>*/}
                                    <AuthRouter exact path='/UserCenter' component={UserCenter}/>
                                    <AuthRouter exact path='/DeviceManagement' component={DeviceTask}/>
                                    <AuthRouter exact path='/TupleManagement' component={FiveTuplesHome}/>
                                    <AuthRouter exact path='/DeviceDetails/:id' component={DeviceDetails}/>
                                    <AuthRouter exact path='/PerformanceTest/' component={PerformanceTestHome}/>
                                    <AuthRouter exact path='/PerformanceTestDetails/:id'
                                                component={PerformanceTestDetails}/>
                                    <AuthRouter exact path='/ServiceAvailability/' component={ServiceAvailability}/>
                                    <AuthRouter exact path='/ServiceDetail/:apptype/:cycle' component={ServiceDetail}/>
                                    <AuthRouter exact path='/DevicesDetail/:apptype/:cycle' component={DevicesDetail}/>
                                    <AuthRouter exact path='/Repository/' component={Repository}/>
                                    <AuthRouter exact path='/TestProject/' component={TestProject}/>
                                    <AuthRouter exact path='/TaskList/:id/:name' component={TaskList}/>
                                    {/*<AuthRouter exact path='/TestTaskHome/' component={TestTaskListHome}/>*/}
                                    <AuthRouter exact path='/ProjectTesting/' component={ProjectTestingHome}/>
                                    <AuthRouter exact path='/ProjectTaskDetails/:id' component={ProjectTaskDetails}/>
                                    <AuthRouter exact path='/ProjectTestConfig' component={ProjectConfig}/>
                                    <AuthRouter exact path='/ProjectTestDatas' component={ProjectTestDatas}/>
                                    <AuthRouter exact path='/BugDashboard' component={BugDashboardHome}/>
                                    <AuthRouter exact path='/UserConfig' component={UserConfig}/>
                                    <AuthRouter exact path='/AppProjectDetails/:id' component={AppProjectDetails}/>
                                    <AuthRouter exact path='/FirmwareTaskDetails/:id' component={FirmwareTaskDetails}/>
                                </Switch>
                                <Footer
                                    style={{
                                        textAlign: 'center',
                                        background: '#042e79',
                                        color: "#ffffff",
                                    }}
                                >
                                    <b>BULL 公牛 ©2023 Created by 智能照明事业部</b>
                                </Footer>
                            </Content>
                        </Layout>
                    </Layout>
                </BrowserRouter>
            </Spin>
        );
    }
}

export default withRouter(MenuPage)