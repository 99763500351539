import React, {Component} from 'react'
import {
    Steps,
    Col,
    Descriptions,
    Badge,
    Row,
    Space,
    Tag,
    Card,
    Button,
    Statistic,
    Divider,
    Progress,
    Tooltip
} from "antd";
import Avatar from "antd/es/avatar/avatar";
import api from "../../../api/ApiConfig";
import {Loginout} from "../../Users/AuthRouter";
import QRCodeComponent from "./qrcode";

export class Step5 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            datas: props.datas,
            taskId: props.taskId,
            projectDatas: {}
        }

    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.datas) !== JSON.stringify(prevProps.datas)) {
            this.setState({
                datas: this.props.datas,
            })
        }
    }

    componentDidMount() {
        this.get_project_tasks_app_status()
        this.get_app_project_tasks()
    }

    get_app_project_tasks = async (params = {}) => {
        this.setState({
            spinning: true
        })
        params['key'] = this.state.taskId
        await api.GetAppProjectTasks(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    projectDatas: r.datas,
                }, function () {
                })
            }
            setTimeout(() => {
                this.setState({
                    spinning: false
                })
            }, 100)

        })
    }

    get_project_tasks_app_status = async (params = {}) => {
        params['key'] = this.state.taskId
        await api.ProjectTasksAppStatus(params).then(r => {
            if (r.msgcode === 20008) {
                Loginout()
                this.props.history.push('/login')
                window.location.reload()
            }
            if (r.msgcode === 200) {
                this.setState({
                    datas: r.datas,
                }, function () {
                })
            }
        })
    }


    render() {
        return (
            <div style={{margin: 20}}>
                <Descriptions
                    title={<Space size={5}>
                        <svg d="1716099701095" className="icon" viewBox="0 0 1024 1024" version="1.1"
                             xmlns="http://www.w3.org/2000/svg" p-id="11235" width="22" height="22">
                            <path
                                d="M84.4 84.6L16 153V592h224v80H16v288h480v-128l64.4 0.4 64.4 0.6 2.4 14.2c11.2 66.8 56.4 122.8 119 147.6 24.6 9.6 38.6 12 69.8 12 31.2 0 45.2-2.4 69.8-12 48.4-19.2 89.8-60.6 109-109 9.6-24.6 12-38.6 12-69.8 0-40.8-7.8-68.8-28.4-102-11.8-19.2-41.2-48.6-60.4-60.4-33.2-20.6-61.2-28.4-102-28.4-40.4 0-64.8 6.6-98.6 26.6-47.2 27.8-84.4 83.2-91 135.6l-1.4 11.6-64.6 0.6-64.4 0.4v-128H272v-80h224V336h64v160h320V263.2l56.6-57c61.8-62.4 65.6-67.2 70-93C1016 57.8 966.2 8 910.8 17.4 884.2 22 881.6 24 802 103.2L728.8 176H560v128h-64V16H153L84.4 84.6zM464 304v256H48V176h32v64h352V80H176V48h288v256zM947 53.6c9.4 4.8 19 14.6 24.2 24.4 2.6 5 3.8 11.2 3.8 22 0 18.4-3.8 26.2-19.8 42l-11.2 11-36.4-36.6L871 80l11-11.2c16.6-16.8 26.2-21 45-20 7 0.4 15.4 2.4 20 4.8zM144 108v36H71l36-36c19.8-19.8 36.2-36 36.4-36 0.4 0 0.6 16.2 0.6 36z m717 8l12 12-60.6 60.6-60.4 60.4-12.4-12.6-12.6-12.4 60-60C820 131 847.4 104 848 104c0.6 0 6.4 5.4 13 12zM400 160v48H112V176h64V112h224v48z m509 4l12 12-60.6 60.6-60.4 60.4-12.4-12.6-12.6-12.4 60-60C868 179 895.4 152 896 152c0.6 0 6.4 5.4 13 12z m-216.8 49.4c-2.2 3.2-11.2 28-20.2 55.4-13.6 41.6-16.2 51.6-15.6 59.4 2 20.8 19.4 37.8 40.4 39.2 8.6 0.6 18.2-2 61.2-16.2l50.8-17 19.6-19.6 19.6-19.6V464H592V208h104.2l-4 5.4z m43.4 111.2c-52.2 17.4-53.4 16.2-36.4-35.4l11.8-35.2 29.4 29.4 29.6 29.6-34.4 11.6z m118.4 336c41.8 11.4 73.4 34.2 96.2 69 17.6 26.8 24.8 51.6 24.8 86.4 0 45.2-15 81.6-46.2 112.8-62 62-162.4 62.4-224.6 1-32.2-31.8-47.2-68-47.2-113.8 0-34.8 7.2-59.4 25-86.8 23.6-36 60.6-61.2 104-70.4 16-3.4 52.4-2.4 68 1.8zM464 816v112H48V704h416v112z"
                                p-id="11236"/>
                            <path
                                d="M80 288v16h32v-32H80v16zM144 288v16h288v-32H144v16zM80 352v16h352v-32H80v16zM80 464v64h352v-128H80v64z m320 0v32H112v-64h288v32zM624 416v16h192v-32h-192v16zM849.6 823.4L784 889l-25.6-25.6c-14-14-26.4-25.4-27.6-25.4-2.4 0-20.8 18.4-20.8 21 0 1.2 16.6 18.6 37 39l37 37 77-77c42.4-42.4 77-78 77-79 0-2.6-18.4-21-21-21-1.2 0-31.4 29.4-67.4 65.4zM112 770.6c-9.8 3.2-26.6 20.4-29.6 30.6-5.6 18.8-1.6 33.8 12.6 47.8 20.2 20.4 45.8 20.4 66 0 19.6-19.4 20.2-44.4 1.6-65-13-14.4-31.6-19.4-50.6-13.4z m27 34.4c3.2 3 5 7.2 5 11s-1.8 8-5 11c-9.8 10-27 3-27-11 0-8.2 7.8-16 16-16 3.8 0 8 1.8 11 5zM240 770.6c-9.8 3.2-26.6 20.4-29.6 30.6-5.6 18.8-1.6 33.8 12.6 47.8 20.2 20.4 45.8 20.4 66 0 19.6-19.4 20.2-44.4 1.6-65-13-14.4-31.6-19.4-50.6-13.4z m27 34.4c10 9.8 3 27-11 27-3.8 0-8-1.8-11-5-3.2-3-5-7.2-5-11s1.8-8 5-11c3-3.2 7.2-5 11-5s8 1.8 11 5zM368 770.6c-9.8 3.2-26.6 20.4-29.6 30.6-5.6 18.8-1.6 33.8 12.6 47.8 20.2 20.4 45.8 20.4 66 0 19.6-19.4 20.2-44.4 1.6-65-13-14.4-31.6-19.4-50.6-13.4z m27 34.4c10 9.8 3 27-11 27-3.8 0-8-1.8-11-5-3.2-3-5-7.2-5-11s1.8-8 5-11c3-3.2 7.2-5 11-5s8 1.8 11 5z"
                                p-id="11237"/>
                        </svg>
                        <div style={{marginTop: -6}}>提测结果</div>
                    </Space>}
                    style={{display: "block", width: "100%", margin: "0 auto"}}
                    bordered={true}
                    contentStyle={{fontWeight: 700}}
                >
                    <Descriptions.Item label="提测结果" span={1}>
                        <Space>
                            <Tag color={this.state.projectDatas.ProjectTaskStatus === 1 ? "#fadb14" :
                                this.state.projectDatas.ProjectTaskStatus === 2 ? "#9254de" :
                                    this.state.projectDatas.ProjectTaskStatus === 3 ? "green-inverse" :
                                        this.state.projectDatas.ProjectTaskStatus === 4 ? "#ff7875" :
                                            "#707272"
                            }
                                 style={{
                                     color: "white",
                                     marginRight: 5,
                                     textAlign: "center",
                                     fontSize: 12,
                                     fontWeight: 700,
                                     width: 80
                                 }}
                            >
                                {this.state.projectDatas.ProjectTaskStatus === 1 ? "待确认" :
                                    this.state.projectDatas.ProjectTaskStatus === 2 ? "进行中" :
                                        this.state.projectDatas.ProjectTaskStatus === 3 ? "提测成功" :
                                            this.state.projectDatas.ProjectTaskStatus === 4 ? "提测失败" :
                                                "未知状态"}
                            </Tag>
                        </Space>
                    </Descriptions.Item>
                    <Descriptions.Item label="提测标题" span={2}>
                        {this.state.projectDatas.AppTitle}
                    </Descriptions.Item>
                    <Descriptions.Item label="苹果提测版本" span={1}>
                        {this.state.projectDatas.IosVersion}
                    </Descriptions.Item>
                    <Descriptions.Item label="安卓提测版本" span={1}>
                        {this.state.projectDatas.AndroidVersion}
                    </Descriptions.Item>
                    <Descriptions.Item label="鸿蒙提测版本" span={1}>
                        {this.state.projectDatas.HarmonyOSVersion}
                    </Descriptions.Item>
                    <Descriptions.Item label="苹果打包结果" span={1}>
                        {this.state.datas.IosBuildResult === 0 ?
                            <Tag color={"green-inverse"}>Success</Tag> :
                            this.state.datas.IosBuildResult === -1 ?
                                <Tag color={"red-inverse"}>Failed</Tag> :
                                this.state.datas.IosBuildResult === 3 ?
                                    <Tag color={"default"}>Uninvolved</Tag> :
                                    <Tag color={"blue-inverse"}>Building</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="安卓打包结果" span={1}>
                        {this.state.datas.AndroidBuildResult === 0 ?
                            <Tag color={"green-inverse"}>Success</Tag> :
                            this.state.datas.AndroidBuildResult === -1 ?
                                <Tag color={"red-inverse"}>Failed</Tag> :
                                this.state.datas.AndroidBuildResult === 3 ?
                                    <Tag color={"default"}>Uninvolved</Tag> :
                                    <Tag color={"blue-inverse"}>Building</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="鸿蒙打包结果" span={1}>
                        {this.state.datas.HarmonyOSBuildResult === 0 ?
                            <Tag color={"green-inverse"}>Success</Tag> :
                            this.state.datas.HarmonyOSBuildResult === -1 ?
                                <Tag color={"red-inverse"}>Failed</Tag> :
                                this.state.datas.HarmonyOSBuildResult === 3 ?
                                    <Tag color={"default"}>Uninvolved</Tag> :
                                    <Tag color={"blue-inverse"}>Building</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="苹果测试结果" span={1}>
                        {this.state.datas.IosAutoTest === 0 ?
                            <Tag color={"green-inverse"}>Success</Tag> :
                            this.state.datas.IosAutoTest === 4 ?
                                <Tooltip title={<div style={{fontSize: 12, fontWeight: 600}}>
                                    手动通过类型:
                                    【{this.state.datas.IosManualValue === 1 ? "环境问题" :
                                    this.state.datas.IosManualValue === 2 ? "服务依赖" :
                                        this.state.datas.IosManualValue === 3 ? "用例问题" :
                                            this.state.datas.IosManualValue === 4 ? "新增需求" :
                                                this.state.datas.IosManualValue === 5 ? "其他问题" : "未知问题"}】
                                    <br/>
                                    手动通过描述：
                                    {this.state.datas.IosManualText}
                                </div>} placement={"top"} color={"geekblue"}>
                                    <Tag color={"green-inverse"}>Manual</Tag>
                                </Tooltip> :
                                this.state.datas.IosAutoTest === -1 ?
                                    <Tag color={"red-inverse"}>Failed</Tag> :
                                    this.state.datas.IosAutoTest === 3 ?
                                        <Tag color={"default"}>Uninvolved</Tag> :
                                        <Tag color={"red-inverse"}>Testing</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="安卓测试结果" span={1}>
                        {this.state.datas.AndroidAutoTest === 0 ?
                            <Tag color={"green-inverse"}>Success</Tag> :
                            this.state.datas.AndroidAutoTest === 4 ?
                                <Tooltip title={<div style={{fontSize: 12, fontWeight: 600}}>
                                    手动通过类型:
                                    【{this.state.datas.AndroidManualValue === 1 ? "环境问题" :
                                    this.state.datas.AndroidManualValue === 2 ? "服务依赖" :
                                        this.state.datas.AndroidManualValue === 3 ? "用例问题" :
                                            this.state.datas.AndroidManualValue === 4 ? "新增需求" :
                                                this.state.datas.AndroidManualValue === 5 ? "其他问题" : "未知问题"}】
                                    <br/>
                                    手动通过描述：
                                    {this.state.datas.AndroidManualText}
                                </div>} placement={"top"} color={"geekblue"}>
                                    <Tag color={"green-inverse"}>Manual</Tag>
                                </Tooltip> :
                                this.state.datas.AndroidAutoTest === -1 ?
                                    <Tag color={"red-inverse"}>Failed</Tag> :
                                    this.state.datas.AndroidAutoTest === 3 ?
                                        <Tag color={"default"}>Uninvolved</Tag> :
                                        <Tag color={"blue-inverse"}>Testing</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="鸿蒙测试结果" span={1}>
                        {this.state.datas.HarmonyOSAutoTest === 0 ?
                            <Tag color={"green-inverse"}>Success</Tag> :
                            this.state.datas.HarmonyOSAutoTest === 4 ?
                                <Tooltip title={
                                    <div style={{fontSize: 12, fontWeight: 600}}>
                                        手动通过类型:
                                        【{this.state.datas.HarmonyOSManualValue === 1 ? "环境问题" :
                                        this.state.datas.HarmonyOSManualValue === 2 ? "服务依赖" :
                                            this.state.datas.HarmonyOSManualValue === 3 ? "用例问题" :
                                                this.state.datas.HarmonyOSManualValue === 4 ? "新增需求" :
                                                    this.state.datas.HarmonyOSManualValue === 5 ? "其他问题" : "未知问题"}】
                                        <br/>
                                        手动通过描述：
                                        {this.state.datas.HarmonyOSManualText}
                                    </div>
                                } placement={"top"} color={"geekblue"}>
                                    <Tag color={"green-inverse"}>Manual</Tag>
                                </Tooltip> :
                                this.state.datas.HarmonyOSAutoTest === -1 ?
                                    <Tag color={"red-inverse"}>Failed</Tag> :
                                    this.state.datas.HarmonyOSAutoTest === 3 ?
                                        <Tag color={"default"}>Uninvolved</Tag> :
                                        <Tag color={"blue-inverse"}>Testing</Tag>}
                    </Descriptions.Item>
                    <Descriptions.Item label="苹果扫码安装" span={1}>
                        {
                            this.state.datas.IosPkgUrl !== "" ?
                                <img style={{width: 165, height: 165}} src={this.state.datas.IosPkgUrl}
                                     alt={""}/>
                                : <div style={{width: 165, height: 165}}/>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="安卓扫码安装" span={1}>
                        {
                            this.state.datas.AndroidPkgUrl !== "" ?
                                <QRCodeComponent
                                    qrCodeValue={this.state.datas.AndroidPkgUrl}
                                    logoUrl={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico"}/> :
                                <div style={{width: 165, height: 165}}/>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="鸿蒙扫码安装" span={1}>
                        {
                            this.state.datas.HarmonyOSPkgUrl !== "" ?
                                <QRCodeComponent
                                    qrCodeValue={this.state.datas.HarmonyOSPkgUrl}
                                    logoUrl={"https://zzy-9345.oss-cn-hangzhou.aliyuncs.com/favicon.ico"}/> :
                                <div style={{width: 165, height: 165}}/>
                        }
                    </Descriptions.Item>
                </Descriptions>
            </div>
        );
    }
}
